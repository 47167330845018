const config = {
  // Landing Page
  landingcontent: {
    title: "Kaiser’s Pizza Pub was voted Best Pizza in Gurnee!",
    subtitle:
      "Voted Gurnee's Best' Pizza and Family Restaurant, featuring a full menu with daily food and drink",
    description:
      "Gurnee's Best Pizza and Family Restaurant, offering a diverse menu of delicious dishes and refreshing drinks in a welcoming atmosphere, voted by the community.",
    keywords:
      "Homepage, Kaiser's Pizza & Pub, Gurnee, 1801 North, 1801 IL-21, Gurnee, IL 60031, United States, World Famous Pizza & Pub, Thin Crust, Pan, Double Decker pizzas, freshest ingredients, Wisconsin Cheese, hot pizzas in cold weather, Kaiser’s Specialty Pizzas, Kid's Menu, Best Pizza in Gurnee, full menu, daily food, drink, Facebook, Instagram, Twitter, Yelp, TikTok, TripAdvisor, comfort-food menu, full bar, tavern surrounds, Dine-In, Carry Out, Delivery, Catering, hours of operation, happy hour food, sports, Family-owned & Operated, Vegetarian Options, Outdoor Seating, Northeastern Illinois, cold beer, hot fresh pizza, weekend fun, Pizza, Burgers, Beer, weekend enjoyment, delivery service, Cayman Lemonade, Vodka, Triple Sec, Club Soda, Sour Mix, Cranberry Juice, summer mood, popular appetizers, Loaded Sidewalk Fries, Kaiser’s Nachos.",
  },

  // About Us Page
  aboutcontent: {
    title: "A Slice of Perfection in Northeastern Illinois",
    subtitle:
      "Home of World-Famous Pizza, Family Atmosphere, and Award-Winning Dining",
    description:
      "Discover the warmth of Kaiser's Pizza & Pub in Gurnee, where we serve the world's most perfect food – renowned thin crust, pan, and double decker pizzas made with the freshest ingredients and Wisconsin cheese, voted the Best Pizza in Gurnee.",
    keywords:
      "Pizza, Pub, Gurnee, World-Famous, Thin Crust, Pan Pizza, Double Decker Pizza, Wisconsin Cheese, Fresh Ingredients, Best Pizza in Gurnee, Family Restaurant, Full Menu, Daily Specials, Comfort Food, Tavern, Happy Hour, Sports Bar, Family-Owned, Northeastern Illinois, Vegetarian Options, Outdoor Seating, Catering, Kid's Menu, Delivery, Carry Out, Dine-In, Cold Beer, Hot Pizza, Weekend Specials, Burgers, Beer, Summer Drinks, Cayman Lemonade, Loaded Sidewalk Fries, Nachos, Vodka, Triple Sec, Club Soda, Sour Mix, Cranberry Juice, Award-Winning, Facebook, Instagram, Twitter, TikTok, Yelp, TripAdvisor, Maps, Eatery, Open All Day.",
    header: "Where Every Slice is an Experience!",
    message:
      "Join us at Kaiser's Pizza & Pub in Gurnee for a mouthwatering journey of world-famous pizzas, family-friendly ambiance, and an award-winning dining experience that goes beyond the ordinary.",
  },
  aboutbusiness: {
    paragraph1:
      "At Kaiser's Pizza & Pub in Gurnee, we take pride in serving the community the world's most perfect food – our renowned thin crust, pan, and double-decker pizzas crafted with the freshest ingredients, topped generously with the finest Wisconsin cheese. Voted the Best Pizza in Gurnee, our establishment stands as a testament to our commitment to excellence in every slice. As a family-owned and operated business, we prioritize creating a warm and inviting atmosphere for our patrons, offering a full menu that goes beyond just pizza. With daily specials, comfort food, and a variety of options, Kaiser's is not just a pizza joint; it's a destination where families and friends gather to enjoy delicious meals, watch sports, and create lasting memories.",
    paragraph2:
      "Join us at Kaiser's Pizza & Pub for an unparalleled dining experience in Northeastern Illinois. Whether you're indulging in our signature pizzas, sipping on refreshing drinks like our popular Cayman Lemonade, or enjoying our award-winning loaded Sidewalk Fries and Kaiser’s Nachos, our commitment to quality, flavor, and a family-friendly environment sets us apart. Open throughout the week for dine-in, carry-out, delivery, and catering services, Kaiser's is not just a restaurant; it's a culinary journey that elevates the pizza experience, making every visit a delightful celebration of good food and great company.",
  },
  littleaboutus: {
    paragraph1:
      "Experience swift satisfaction at AJ's Keto Factory – your hub for mouthwatering, low-carb meals on the fly! Discover a menu brimming with wheat-free, gluten-free, sugar-free, and soy-free options, carefully prepared daily for a fresh and preservative-free feast. But AJ's is more than just a culinary delight; it's a Kingdom Business, guided by the Holy Spirit, committed to meeting the community's needs. Join us for a taste of purpose at AJ's Keto Factory, where flavor and wellness intertwine in every delectable bite!",
    paragraph2:
      "At AJ's, we're redefining on-the-go dining with our commitment to delicious and health-conscious choices. Our menu features an array of low-carb options that are not only convenient but also crafted with precision – wheat-free, gluten-free, sugar-free, and soy-free. Beyond being your destination for a quick and satisfying meal, we're a Kingdom Business, driven by a higher purpose to serve and uplift our community. Join us at AJ's Keto Factory for a flavorful journey where every bite tells a story of wellness and purpose!",
    paragraph3:
      "Savor the essence of guilt-free indulgence at our Keto haven! Delight in a symphony of flavors, from gluten-free and sugar-free to soy-free and wheat-free options. Whether you opt for dine-in luxury or the convenience of curbside pickup and no-contact delivery, our culinary journey awaits. Nestled in St. Charles, Geneva, and South Elgin, our oasis beckons with delectable keto creations made from fresh, natural ingredients. Dive into our gourmet, carb-free offerings, featuring tantalizing keto bakery treats and a medley of satisfying options that redefine the art of healthy dining. Immerse yourself in the essence of the keto lifestyle with our nutrient-rich, high-quality fare, setting the standard for excellence in St. Charles, Geneva, and South Elgin.",
  },

  // Contact Page
  contactcontent: {
    title: "Home of World Famous Thin Crust, Pan, and Double Decker Pizzas",
    description:
      "Discover the world's most perfect food at Kaiser's Pizza & Pub in Gurnee, known for our fresh ingredients and Wisconsin Cheese, voted the Best Pizza in Northeastern Illinois.",
    keywords:
      "Gurnee, Pizza, Pub, Thin Crust, Pan Pizza, Double Decker Pizza, Wisconsin Cheese, Best Pizza, Family Restaurant, Comfort Food, Full Bar, Tavern, Dine-In, Carry Out, Delivery, Catering, Happy Hour, Sports, Vegetarian Options, Outdoor Seating, Family-Owned, Northeastern Illinois, Kid's Menu, Kaiser's Specialty Pizzas.",
    message:
      "Indulge in the world's best pizza experience at Kaiser's - voted Gurnee's Best! Join us for a cozy dining atmosphere, delicious thin crust, pan, and double decker pizzas, and a full bar. Open every day to serve you!",
  },

  // Photo Gallery Page
  gallerycontent: {
    title: "Capturing the Essence of Kaiser's Culinary Delights and Atmosphere",
    description:
      "Explore the visual feast of Kaiser's Pizza & Pub in Gurnee through our photo gallery, showcasing the world-famous pizza, vibrant ambiance, and delectable specialties.",
    keywords:
      "Kaiser's Pizza, Pub, Gurnee, Photo Gallery, Thin Crust, Pan Pizza, Double Decker Pizza, World Famous, Wisconsin Cheese, Perfect Food, Fresh Ingredients, Cold Outside, Hot Pizzas, Great Food, Family Atmosphere, Open All Day, Specialty Pizzas, Kid's Menu, Best Pizza in Gurnee, Daily Specials, Full Menu, Comfort Food, Tavern, Dine-In, Carry Out, Delivery, Catering, Happy Hour, Sports Watching, Vegetarian Options, Outdoor Seating, Family-Owned, Northeastern Illinois, Weekend Vibes, Burgers, Beer, Delivery, Cayman Lemonade, Sidewalk Fries, Nachos, Social Media, Facebook, Instagram, Twitter, TikTok, Yelp, TripAdvisor, Maps, Eatery, Fun-filled, Summer Mood",
    message:
      " Immerse yourself in the visual delights of Kaiser's Pizza & Pub, Gurnee's voted Best Pizza spot, and experience the warmth of our family-friendly atmosphere and mouthwatering dishes.",
  },

  // Menu Page
  ordercontent: {
    title: "World Famous Pizza & Pub - Voted Best Pizza in Gurnee!",
    description:
      "Indulge in the world's most perfect food at Kaiser's Pizza & Pub in Gurnee, renowned for thin crust, pan, and double-decker pizzas made with the freshest ingredients and Wisconsin Cheese.",
    keywords:
      "Kaiser's Pizza, Gurnee, Thin Crust, Pan Pizza, Double Decker Pizza, Wisconsin Cheese, Best Pizza, Family Restaurant, Full Menu, Daily Specials, Comfort Food, Tavern Atmosphere, Happy Hour, Sports Bar, Vegetarian Options, Outdoor Seating, Catering, Kid's Menu, Eatery, Carry Out, Delivery, Dine-In, Open All Day, Northeastern Illinois, Cold Beer, Hot Pizza, Weekend Specials, Burgers, Beer, Summer Drinks, Refreshing Cocktails, Loaded Sidewalk Fries, Nachos, Vodka, Triple Sec, Club Soda, Sour Mix, Cranberry Juice, Fun Atmosphere, Sports on TV, Open Hours, Facebook, Instagram, Twitter, Yelp, TikTok, TripAdvisor, Map, Best Pizza Award.",
    message:
      "Join us at Kaiser's Pizza & Pub in Gurnee for a delightful experience featuring award-winning pizzas, a variety of comfort food, refreshing drinks, and a welcoming atmosphere – the perfect destination for families, friends, and pizza enthusiasts alike!",
  },

  // Eloyalty Page
  eloyaltycontent: {
    title:
      "Join our eLoyalty Club for exclusive member benefits, specials, and events, earning Bucks for loyalty rewards.",
    description:
      "Enjoy the world’s most perfect food at Kaiser’s Pizza & Pub in Gurnee, featuring renowned Thin Crust, Pan, and Double Decker pizzas made with the freshest ingredients and Wisconsin Cheese, voted the Best Pizza in Northeastern Illinois.",
    keywords:
      "Kaiser's Pizza, Gurnee, Thin Crust Pizza, Pan Pizza, Double Decker Pizza, Wisconsin Cheese, Best Pizza, Family Restaurant, Comfort Food, Full Bar, Tavern, Happy Hour, Sports, Family-owned, Vegetarian Options, Outdoor Seating, Catering, Kid's Menu, Hot Pizza, Fresh Ingredients, Northeastern Illinois, Dine-In, Carry Out, Delivery, Catering, Monday-Sunday Hours, Facebook, Instagram, Twitter, Yelp, TikTok, TripAdvisor, Eatery, Good for Kids, Cold Beer, Hot Pizza, Weekend Specials, Burgers, Beer, Delivery Service, Cayman Lemonade, Loaded Sidewalk Fries, Kaiser's Nachos, Summer Mood, Appetizers.",
    message:
      " Experience pizza perfection at Kaiser's Pizza & Pub - Gurnee, where our Thin Crust, Pan, and Double Decker pizzas, voted the Best in Northeastern Illinois, promise a delightful escape into a world of flavor and fun!",
  },
};

export default config;
