import "../../../App.css";
import { Container, Row, Col } from "react-bootstrap";
import LogoCenter from "../components/LogoCenter";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation, withRouter } from "react-router-dom";
import BlankSpace from "../components/BlankSpace";
import BottomNav from "../components/BottomNav";
import DrawerRight from "../components/DrawerRight";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {
  Typography,
  Button,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import { CardActionArea } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BarTop from "../components/BarTop";
import BarBottom from "../components/BarBottom";
import { useSelector } from "react-redux";
import config from "../../../config";
import { Helmet, HelmetProvider } from "react-helmet-async";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
const enableSL = parseInt(process.env.REACT_APP_ENABLE_SL);
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
let enableNewUI = process.env.REACT_APP_NEWUI_LW;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;

export const Welcome = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [servingPeriod, setServingPeriod] = useState([]);
  const [revenueCenter, setRevenueCenter] = useState([]);
  const [menuName, setMenuName] = useState([]);
  const [selectedRevenueCenter, setSelectedRevenueCenter] = useState(
    state?.revenueCenter || "none"
  );
  const [selectedServingPeriod, setSelectedServingPeriod] = useState("none");
  const [selectedMenuName, setSelectedMenuName] = useState(
    state?.menuName || "none"
  );
  const { cartItems } = useSelector((state) => state.cart);
  const iframe = {
    width: "100%",
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (enableSL === 1) {
      if (sessionStorage.getItem("pinCodeOk") !== "1") {
        //sessionStorage.setItem("categoryUrl", current_url);
        history.push("/");
      }
    }
  }, []);

  useEffect(() => {
    setSelectedRevenueCenter(state?.revenueCenter || "none");
    sessionStorage.setItem("servingPeriod", "none");
  }, [state]);

  //Get Revenue Center or Serving Period
  useEffect(() => {
    const url =
      enableRevenueCenter == 1
        ? "https://ecommv2.servingintel.com/items/revenue-center/" +
          serverId +
          "/" +
          siteId
        : "https://ecommv2.servingintel.com/items/serving-period/" +
          serverId +
          "/" +
          siteId +
          "/" +
          storeId;
    axios
      .get(url)
      .then((response) => {
        if (enableRevenueCenter == 1) {
          setRevenueCenter(response.data);
        } else {
          setServingPeriod(response.data);
        }
      })
      .catch((error) => {});
  }, []);

  //Get Revenue Center Menu
  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/items/revenue-menu/" +
      serverId +
      "/" +
      siteId +
      "/" +
      selectedRevenueCenter;
    if (selectedRevenueCenter != "none") {
      axios
        .get(url)
        .then((response) => {
          if (response.data != "") {
            setMenuName(response.data);
          } else {
            history.push({
              pathname: "/menu",
              state: {
                servingPeriod: selectedServingPeriod,
                revenueCenter: selectedRevenueCenter,
                menuName: selectedMenuName,
              },
            });
          }
        })
        .catch((error) => {});
    }
  }, [selectedRevenueCenter]);

  const getSelectedRevenueCenter = (revenueCenter) => {
    const isAllowToCart = !cartItems.some(
      (item) => item.revenueCenter != revenueCenter
    );

    if (!isAllowToCart) {
      Swal.fire({
        title: "Store Message",
        text:
          "You have item(s) in your cart from other location. Please complete or clear your cart before switching to " +
          revenueCenter +
          ".",
        icon: "info",
        confirmButtonText: "OK",
      });
      setSelectedRevenueCenter("none");
    } else {
      setSelectedRevenueCenter(revenueCenter);
      sessionStorage.setItem("revenueCenter", revenueCenter);
    }
  };

  const [landingContent, setlandingContent] = useState("");

  useEffect(() => {
    const landingContentText = config.landingcontent;

    setlandingContent(landingContentText);
  }, []);

  return (
    <div
      className="Welcome modal-container"
      style={
        enableSL === 0
          ? {
              background: `linear-gradient(rgba(255, 255, 255, .05), rgba(0, 0, 0, .4)), url(${siteMedSrc}homescreen-bg.png) no-repeat center center`,
            }
          : null
      }
    >
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${restaurantName}: ${tagline}`}</title>
          <link rel="canonical" href="/" />
          <meta name="description" content={`${landingContent.description}`} />
          <meta name="keywords" content={`${landingContent.keywords}`} />
          <meta
            property="og:title"
            content={`${restaurantName}: ${landingContent.title}`}
          />
          <meta
            property="og:description"
            content={`${landingContent.description}`}
          />
        </Helmet>
      </HelmetProvider>
      {enableSL === 1 && (
        <LogoCenter selectedRevenueCenter={selectedRevenueCenter} />
      )}
      <BlankSpace />
      {enableSL === 1 && <DrawerRight />}
      <Container fluid="xxl" className="welcomeCustom">
        {enableSL === 0 && (
          <div>
            <div
              style={{
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              <Typography className="title padding-top-20">
                <img
                  src={siteMedSrc + "logo" + ".png"}
                  style={{
                    width: logoWidth,
                    transform: "translate(0, -7.5px) scale(1.5)",
                  }}
                />
              </Typography>
            </div>
            <h2 className="black center padding-top-10">{tagline}</h2>
          </div>
        )}

        <Row className="d-flex flex-wrap welcomeMenuResponsive">
          {enableRevenueCenter == 1 && selectedRevenueCenter == "none"
            ? revenueCenter.map((item) => {
                return (
                  <>
                    {item.RevenueName ? (
                      <Col
                        xs={12}
                        sm={6}
                        className="blankspace center"
                        key={item.RevenueName}
                      >
                        {enableNewUI === "0" ? (
                          <Card>
                            <CardActionArea
                              className="cardAreaMenu"
                              onClick={() => {
                                getSelectedRevenueCenter(item.RevenueName);
                              }}
                            >
                              <div className="welcomeImage">
                                <CardMedia
                                  component="img"
                                  height="140"
                                  image={siteMedSrc + item.RevenueName + ".png"}
                                  alt={item.RevenueName}
                                />
                              </div>
                              <CardContent sx={{ paddingTop: 0 }}>
                                <Typography
                                  marginTop={4}
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  {item.RevenueName}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        ) : (
                          <Card style={{ backgroundColor: "transparent" }}>
                            <CardActionArea
                              onClick={() => {
                                getSelectedRevenueCenter(item.RevenueName);
                              }}
                            >
                              <CardMedia
                                style={{
                                  borderRadius: "50%",
                                  paddingTop: "100%",
                                }}
                                image={siteMedSrc + item.RevenueName + ".png"}
                                alt={item.RevenueName}
                              />
                              <CardContent>
                                <Typography
                                  className="CenterName"
                                  component="div"
                                  variant="h1"
                                  style={{
                                    lineHeight: ".90",
                                    fontWeight: "400",
                                  }}
                                >
                                  {item.RevenueName}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        )}
                      </Col>
                    ) : null}
                  </>
                );
              })
            : menuName &&
              menuName.map((item) => {
                return (
                  <>
                    <Col xs={12} sm={6} className="blankspace center">
                      {enableNewUI === "0" ? (
                        <Card>
                          <CardActionArea
                            className="cardAreaMenu"
                            onClick={() => {
                              if (item.ShowMenu == 1) {
                                setSelectedMenuName(item.MenuName);
                                sessionStorage.setItem(
                                  "selectedMenu",
                                  item.MenuName
                                );
                                history.push({
                                  pathname: "/menu",
                                  state: {
                                    servingPeriod: selectedServingPeriod,
                                    revenueCenter: selectedRevenueCenter,
                                    menuName: item.MenuName,
                                  },
                                });
                              } else {
                                Swal.fire({
                                  title: "Store Message",
                                  text:
                                    "This menu is only available to order between " +
                                    item.StartTime +
                                    " to " +
                                    item.EndTime,
                                  icon: "info",
                                  confirmButtonText: "OK",
                                });
                              }
                            }}
                          >
                            <div className="welcomeImage">
                              <CardMedia
                                component="img"
                                height="140"
                                image={siteMedSrc + item.MenuName + ".png"}
                                alt={item.RevenueName}
                              />
                            </div>
                            <CardContent sx={{ paddingTop: 0 }}>
                              <Typography
                                marginTop={4}
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                {item.PeriodName
                                  ? item.PeriodName
                                  : item.ServingPeriodName
                                  ? item.ServingPeriodName
                                  : item.MenuName}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      ) : (
                        <Card style={{ backgroundColor: "transparent" }}>
                          <CardActionArea
                            className="cardAreaMenu"
                            onClick={() => {
                              if (item.ShowMenu == 1) {
                                setSelectedMenuName(item.MenuName);
                                sessionStorage.setItem(
                                  "selectedMenu",
                                  item.MenuName
                                );
                                history.push({
                                  pathname: "/menu",
                                  state: {
                                    servingPeriod: selectedServingPeriod,
                                    revenueCenter: selectedRevenueCenter,
                                    menuName: item.MenuName,
                                  },
                                });
                              } else {
                                Swal.fire({
                                  title: "Store Message",
                                  text:
                                    "This menu is only available to order between " +
                                    item.StartTime +
                                    " to " +
                                    item.EndTime,
                                  icon: "info",
                                  confirmButtonText: "OK",
                                });
                              }
                            }}
                          >
                            <CardMedia
                              // style={{
                              //   borderRadius: "50%",
                              //   paddingTop: "100%",
                              // }}
                              component="img"
                              height="140"
                              image={siteMedSrc + item.MenuName + ".png"}
                              alt={item.MenuName}
                            />
                            <CardContent>
                              <Typography
                                className="CenterName"
                                component="div"
                                variant="h5"
                                style={{ lineHeight: ".90", fontWeight: "400" }}
                              >
                                {item.PeriodName
                                  ? item.PeriodName
                                  : item.ServingPeriodName
                                  ? item.ServingPeriodName
                                  : item.MenuName}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      )}
                    </Col>
                  </>
                );
              })}
          {selectedRevenueCenter != "none" && siteId == "216" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                marginTop: "2rem",
              }}
            >
              <iframe
                src="https://v2stoneridge.servingintel.com/qsr-compact.html"
                title="qsr"
                style={{ height: "100%", width: "100%" }}
              ></iframe>
            </div>
          ) : null}
        </Row>
      </Container>
      <BlankSpace />
      <BlankSpace />
      {enableNewUI == 1 ? (
        <>
          <BarTop />
          <BarBottom />
        </>
      ) : null}
      {enableSL == 1 ? (
        <BottomNav sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} />
      ) : null}
    </div>
  );
};
