import React from "react";

const addressMap = process.env.REACT_APP_ADDRESS_MAP;

export const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2946.9541049297923!2d-87.92585442388935!3d42.386124171190616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f8de55e057115%3A0x3c92022818a55ce6!2sKaiser&#39;s%20Pizza%20%26%20Pub!5e0!3m2!1sen!2sph!4v1706101512744!5m2!1sen!2sph"
        width="100%"
        height="450"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
  );
};
